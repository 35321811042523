// eslint-disable-next-line
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <v-row>
      <v-col>
        <avue-form-design
          style="height: 80vh;"
          :include-fields="includeFields"
          :custom-fields="customFields"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        // includeFields: ['input'],
        labelPosition: 'top',
        customFields: [{
          title: '时间线',
          label: '时间线',
          labelWidth: '200px',
          component: 'custom-timeline',
          span: 24,
          icon: 'el-icon-collection-tag',
          params: {
            reverse: false,
            itemList: [{
              content: '支持使用图标',
              timestamp: '2018-04-12 20:46',
              size: 'large',
              type: 'primary',
              icon: 'el-icon-more',
            }, {
              content: '支持自定义颜色',
              timestamp: '2018-04-03 20:46',
              color: '#0bbd87',
            }, {
              content: '支持自定义尺寸',
              timestamp: '2018-04-03 20:46',
              size: 'large',
            }, {
              content: '默认样式的节点',
              timestamp: '2018-04-03 20:46',
            }],
          },
        }, {
          title: '项目名称',
          label: '项目名称',
          labelWidth: '200px',
          component: 'el-input',
          span: 24,
          icon: 'el-icon-collection-tag',
          placeholder: '请输入正确的项目名称',
        }, {
          title: '年度',
          component: 'el-date-picker',
          type: 'year',
          label: '年度',
          labelWidth: '200px',
          span: 2,
          icon: 'el-icon-date',
          placeholder: '请选择项目年度',
        }, {
          title: '市',
          component: 'el-select',
          label: '市',
          labelWidth: '200px',
          span: 2,
          icon: 'el-icon-location-outline',
          placeholder: '请选择所属地市',
        }, {
          title: '所属县区',
          component: 'el-cascader',
          label: '所属县区',
          labelWidth: '200px',
          span: 2,
          icon: 'el-icon-place',
          placeholder: '请选择所属区县',
        }, {
          title: '所属产业',
          component: 'el-select',
          label: '所属产业',
          labelWidth: '200px',
          span: 3,
          icon: 'el-icon-box',
          placeholder: '请选择所属产业',
        }, {
          title: '行业门类',
          component: 'el-select',
          label: '行业门类',
          labelWidth: '200px',
          span: 3,
          icon: 'el-icon-office-building',
          placeholder: '请选择所属行业门类',
        }, {
          title: '带队领导姓名',
          component: 'el-input',
          label: '带队领导姓名',
          labelWidth: '120px',
          span: 3,
          icon: 'el-icon-user',
          placeholder: '请输入带队领导姓名',
        }, {
          title: '职务',
          component: 'el-input',
          label: '职务',
          labelWidth: '200px',
          span: 3,
          icon: 'el-icon-star-off',
          placeholder: '请输入带队领导职务',
        }, {
          title: '访问时间',
          component: 'el-date-picker',
          label: '访问时间',
          labelWidth: '200px',
          span: 4,
          icon: 'el-icon-time',
          placeholder: '请输入带队访问时间',
        }, {
          title: '访问地点',
          component: 'el-input',
          label: '访问地点',
          labelWidth: '200px',
          span: 4,
          icon: 'el-icon-location-information',
          placeholder: '请输入带队访问地点',
        }, {
          title: '投资方名称',
          component: 'el-input',
          label: '投资方名称',
          labelWidth: '200px',
          span: 12,
          icon: 'el-icon-school',
          placeholder: '请输入投资方全称',
        }, {
          title: '注册公司名称',
          component: 'el-input',
          label: '注册公司名称',
          labelWidth: '200px',
          span: 12,
          icon: 'el-icon-school',
          placeholder: '请输入注册公司全称',
        }, {
          title: '项目类型',
          component: 'el-select',
          label: '项目类型',
          labelWidth: '200px',
          span: 4,
          icon: 'el-icon-school',
          placeholder: '请选择项目所属类型',
        }],
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
